document.addEventListener('DOMContentLoaded', function () {
  const modalBg = document.getElementsByClassName("modal-backdrop")[0];
  const openModalTrigger = document.getElementsByClassName('open-modal');
  const closeModal = document.getElementsByClassName("close-modal");
  const sendDataBtn = document.getElementsByClassName("send-data");

  /* Open form popup */
  Array.from(openModalTrigger).forEach(function (element) {
    element.addEventListener('click', function () {
      const modalId = this.dataset.modalId;
      const modal = document.getElementById(modalId);
      modalBg.classList.add('show');
      modal.classList.add('fade', 'show');
    });
  });

  /* Close form popup */
  Array.from(closeModal).forEach(function (element) {
    element.addEventListener('click', function () {
      const modal = document.getElementsByClassName('modal')[0];

      modalBg.classList.remove('show');
      modal.classList.remove('fade', 'show');
    })
  });

  /* Send data */
  function sendData(data, message = '', element = '') {
    // Script to send email
    const sendUrl = 'https://e-sf.grantthornton.pl/php/send-email.php';
    $.ajax({
      type: 'GET',
      url: sendUrl,
      crossDomain: true,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: data,
      success: function (data) {
        element ? element.innerHTML = message : undefined;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        element ? element.innerHTML = 'Mail nie został wysłany - wewnętrzny błąd serwera.' : undefined;
      }
    });
  }

  /* Form handler */
  $('[name=nip]').on('keyup', function (e) {
    var nip = [];
    var formatNip = '';
    var $th = $(this);
    var inputText = $th.val();

    Array.from(inputText).forEach(function (item) {
      nip.push(item);
      if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57) && e.which != 32) {
        $th.val(nip.join('').slice(0, -1));
      }
    });

    formatNip = nip.join('').replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
    $th.val(formatNip);
  });

  Array.from(sendDataBtn).forEach(function (element) {
    element.addEventListener('click', function () {
      const btn = this;
      const infoBox = document.getElementById('form-sent-feedback');
      const formId = btn.dataset.formId;
      const form = document.getElementById(formId);
      const data = {};
      const info = 'Twoja wiadomość został wysłana. Skontaktujemy się z tobą w najbliszym czasie.';
      let error = false;

      // Validation
      Array.from(form).forEach(function (item) {
        let val = item.value;
        const ifSimpleInput = item.required && (val === undefined || val === '');
        const ifCheckbox = item.checked === false && item.type === 'checkbox';

        if (ifSimpleInput || ifCheckbox) {
          item.classList.add('is-invalid');
          error = true;
        } else {
          item.classList.remove('is-invalid');
          data[item.name] = item.value;
        }
      });

      if (!error) {
        $.when(sendData(data, info, infoBox));
      }
    });
  });

  $("#video-cover").on('click', function () {
    var video = $("#video-player").get(0);

    if (video.paused) video.play();
    else video.pause();

    $(this).toggleClass("show");
    return false;
  });

}, false);
